<template>
  <div class="container">
    <content-title :nav="nav"></content-title>
    <el-form
      id="dialogfrom"
      ref="form"
      :model="form"
      :rules="rules"
      class="dialogfrom"
      label-width="150px"
      :inline="true"
      label-position="right"
    >
      <div class="part">
        <p class="part-title">课程基本信息</p>
        <el-form-item label="发布来源" required>
          <el-select v-model="form.create_type" @change="changeCreateType">
            <el-option label="海马后台" :value="1"></el-option>
            <el-option label="教练局教练" :value="2"></el-option>
            <el-option label="用户端" :value="3"></el-option>
            <el-option label="公会" :value="4"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="需求类型" required>
          <el-select v-model="form.type" @change="form.order_type = 0">
            <el-option label="订单" value="order"></el-option>
            <el-option label="需求" value="need"></el-option>
            <el-option label="课包" value="course"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="订单类型" required v-if="form.type == 'order'">
          <el-select v-model="form.order_type" @change="changeOrderType">
            <el-option label="普通订单" :value="0"></el-option>
            <el-option label="学校订单" :value="1"></el-option>
            <!-- <el-option label="转单" :value="2"></el-option>
            <el-option label="陪练订单" :value="3"></el-option> -->
          </el-select>
        </el-form-item>
          <el-form-item label="上架平台" required v-if="form.type == 'need'" key="pl">
           <el-radio-group v-model="form.platform">
            <el-radio label="0">全平台</el-radio>
            <el-radio label="1">教练局</el-radio>
            <el-radio label="2">机构端</el-radio>
          </el-radio-group>
          <div>可以看到需求的平台</div>
        </el-form-item>

       


        <el-form-item :required="true" label="名称" prop="title">
          <el-input v-model="form.title"></el-input>
        </el-form-item>
        <el-form-item
          :required="true"
          label="总课节数(含体验课)"
          prop="course_num"
        >
          <el-input
            v-model.number="form.course_num"
            placeholder="总课节数，含体验课"
            type="number"
            :min="0"
          ></el-input>
        </el-form-item>
        <el-form-item label="体验课节数">
          <el-input
            v-model.number="form.settlement_course"
            type="number"
            :min="0"
          ></el-input>
        </el-form-item>
        <el-form-item label="课程类目" required prop="categories">
          <el-select v-model="form.categories">
            <el-option
              v-for="item in categories_child_list"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="服务方式" required prop="service_type">
          <el-select v-model="form.service_type" @change="changeService">
            <!-- <el-option label="海马场地" :value="1"></el-option> -->
            <el-option label="上门教学" :value="2"></el-option>
            <el-option label="到店上课" :value="3"></el-option>
             <el-option label="陪练" :value="4"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="单课时长" required prop="length_of_lesson">
          <el-input
            v-model.number="form.length_of_lesson"
            type="number"
            :min="0"
          ></el-input>
        </el-form-item>
         <el-form-item label="班级人数" required>
         <el-input
            v-model="form.max_class_num"
            type="number"
            placeholder="班级最多人数"
          ></el-input>人
        </el-form-item>
        <el-form-item label="排课方式" required>
          <el-radio-group v-model="appointment_type">
            <!-- <el-radio :label="1">预约</el-radio> -->
            <el-radio :label="2">固定</el-radio>
          </el-radio-group>
        </el-form-item>
         <el-form-item label="用券" required>
          <el-radio-group v-model="form.coupon_use">
            <el-radio :label="0">不可用</el-radio>
            <el-radio :label="1">可用</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="上课场地">
          <div class="flex">
            <div v-if="form.id">
              {{
                currentVenue.name || (form.map.venue ? form.map.venue.name : "")
              }}
            </div>
            <div v-else>{{ currentVenue.name }}</div>
            <el-button type="text" @click="openVenue">选择场地</el-button>
          </div>
        </el-form-item>
        <div class="row">
          <el-form-item label="课程封面" v-if="type == 'process'">
            <el-image
              class="pic"
              :src="form.face_url"
              :preview-src-list="[form.face_url]"
            />
          </el-form-item>
          <el-form-item label="课程封面" v-else>
            <avatar-uploader
              @getImgUrl="uploadFace"
              :clear="if_clear"
              :url="form.face_url"
            >
            </avatar-uploader>
          </el-form-item>
          <el-form-item label="课程图片" v-if="type == 'process'">
            <el-image
              v-for="(item, index) in form.introduction_url.split(',')"
              :key="index"
              class="pic"
              :src="item"
              :preview-src-list="form.introduction_url.split(',')"
            />
          </el-form-item>
          <el-form-item label="课程图片" v-else>
            <images-uploader
              @getImgUrl="uploadIntroduction"
              :clear="if_clear"
              :urls="form.introduction_url"
            >
            </images-uploader>
          </el-form-item>
        </div>
      </div>

      <div class="part">
        <p class="part-title">课程支付设置</p>
        <div class="row">
          <el-form-item label="用户支付方式">
            <el-radio-group v-model="pay_way">
              <el-radio label="1">单节付</el-radio>
              <!-- <el-radio label="2">全额购</el-radio> -->
            </el-radio-group>
          </el-form-item>
        </div>
        <div class="row" v-if="pay_way == 1">
          <el-form-item label="用户保证金课节数" required>
            <el-input
              v-model.number="form.user_bond"
              placeholder="用户保证金课节数"
              type="number"
              :min="1"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item label="教练保证金课节数" required>
            <el-input
              v-model.number="form.coach_bond"
              placeholder="教练保证金课节数"
              type="number"
              :min="1"
            ></el-input>
          </el-form-item> -->
        </div>
        <div class="row" v-if="pay_way == 2">
          <el-form-item label="可用券数量">
            <el-input
              v-model="form.usable_coupon_num"
              placeholder="可用券数量"
            ></el-input>
          </el-form-item>
        </div>
      </div>

      <div class="part" v-if="form.type == 'need'">
        <p class="part-title">课程价格信息</p>
        <!-- <el-form-item label="信息费" required key="xxf" v-if="form.create_type == 1">
          <el-input v-model="form.amount" placeholder="机构接单信息费"></el-input>
        </el-form-item> -->

        
        <el-form-item label="课程预算">
          <el-input v-model="form.min" placeholder="最低预算"></el-input>
          -
          <el-input v-model="form.max" placeholder="课程预算"></el-input>
        </el-form-item>
      </div>
      <div class="part" v-else>
        <p class="part-title">课程价格信息</p>
        <el-form-item label="总价" required prop="amount">
          <el-input v-model="form.amount" placeholder="总价"></el-input>
          
        </el-form-item>
        <!-- <el-form-item label="权益点数">
          <el-input
            v-model="form.pay_point"
            placeholder="权益点价格"
          ></el-input>
        </el-form-item> -->
        <div class="row">
          <el-form-item label="分成方式">
            <el-radio-group v-model="settlement_type">
              <el-radio :label="1">固定金额</el-radio>
              <el-radio :label="2">比例分成</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>

        <div class="row" v-if="settlement_type == 1" required>
          <el-form-item label="教练结算价" required>
            <el-input
              v-model="form.coach_settlement_cash"
              placeholder="单节课教练结算价 如300"
            ></el-input>
          </el-form-item>
          <el-form-item label="机构结算价" required>
            <el-input
              v-model="form.mechanism_settlement_cash"
              placeholder="单节课机构结算价 如300"
            ></el-input>
          </el-form-item>
        </div>
        <div class="row" v-if="settlement_type == 2">
          <el-form-item label="单节结算价" required>
            <el-input
              v-model="form.settlement_cash"
              placeholder="单节课单节结算价 如300"
            ></el-input>
          </el-form-item>
          <el-form-item label="教练结算比例" required>
            <el-input
              v-model="form.coach_profit"
              placeholder="单节课结算价比例 如0.7结算270"
            ></el-input>
          </el-form-item>
          <el-form-item label="机构结算比例" required>
            <el-input
              v-model="form.mechanism_profit"
              placeholder="单节课结算价比例 如0.7"
            ></el-input>
          </el-form-item>
        </div>
      </div>
      <div class="part">
        <p class="part-title">课程上课信息</p>

        <el-form-item label="上课时间段" prop="time_slot">
          <el-input
            v-model="form.time_slot"
            placeholder="如：周末、上午10;00,不定..."
          ></el-input>
        </el-form-item>
        <el-form-item label="需求标签">
          <el-input
            v-model="form.tags"
            placeholder="青少年,上门体适能等"
          ></el-input>
          多个标签用英文逗号','分隔（发送短信告知教练的关键词）
        </el-form-item>
        <!-- <el-form-item label="是否允许接单">
          <el-switch
            v-model="form.is_receiving"
            active-text="允许"
            inactive-text="不允许"
            :active-value="1"
            :inactive-value="0"
          >
          </el-switch>
        </el-form-item> -->
        <div>
          <!-- <el-form-item label="上课机构" v-if="form.service_type != 2">
            <mechanismSelect
              :mecid.sync="form.mechanism_id"
              @change="mecChange"
            ></mechanismSelect>
          </el-form-item> -->
          <el-form-item label="城市" required>
            <el-cascader
              v-model="cityList"
              :options="cityoptions"
              :props="cityProps"
              :placeholder="city"
              @change="cityChange"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="上课地点" required>
            <el-input v-model="form.address">
              <addr-select
                slot="append"
                :clear="if_clear"
                @getform="changeAddr"
                type="icon"
              ></addr-select>
            </el-input>
          </el-form-item>
          <el-form-item label="经度">
            <el-input v-model="form.longitude" placeholder="经度"></el-input>
          </el-form-item>
          <el-form-item label="纬度">
            <el-input v-model="form.latitude" placeholder="纬度"></el-input>
          </el-form-item>
        </div>
        <div class="row">
          <el-form-item label="开始时间">
            <el-date-picker
              v-model="form.start_time"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="选择日期"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="结束时间">
            <el-date-picker
              v-model="form.end_time"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="选择日期"
              @change="endtimeChange"
            ></el-date-picker>
          </el-form-item>
        </div>
        <div class="row">
          <el-form-item label="联系人">
            <el-input v-model="form.name" placeholder="联系人"></el-input>
          </el-form-item>
          <el-form-item label="联系电话">
            <el-input v-model="form.phone" placeholder="联系电话"></el-input>
          </el-form-item>
          <div>
            教练接单后给教练展示
          </div>
        </div>
        <div class="row" v-if="form.type == 'need'">
          <el-form-item label="需求上架时间">
            <el-radio-group v-model="downTime">
              <el-radio
                :label="item.name"
                v-for="(item, index) in downList"
                :key="index"
                >{{ item.label }}</el-radio
              >
            </el-radio-group>
          </el-form-item>
          <el-form-item label="是否发送短信">
            <el-switch
              v-model="send_sms"
              active-color="#13ce66"
              :active-value="1"
              :inactive-value="0"
              inactive-color="#eee"
            >
            </el-switch>
          </el-form-item>
        </div>
      </div>
      <div class="part">
        <p class="part-title">其他课程信息</p>
        <el-form-item label="班型">
          <el-input
            v-model="form.connect_peoplenum"
            placeholder="请输入班型"
          ></el-input>
        </el-form-item>
        <el-form-item label="学习基础">
          <el-select v-model="form.study_base">
            <el-option
              v-for="item in base_list"
              :key="item.id"
              :label="item.name"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="适用年龄">
          <el-input v-model="form.apply_age"></el-input>
        </el-form-item>
        <el-form-item label="面向人群">
          <el-select v-model="form.age_grade" clearable>
            <el-option
              v-for="(item, index) in age_grade_list"
              :key="index + 'base'"
              :label="item.name"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <div class="row">
          <el-form-item label="课程简介">
            <el-input
              type="textarea"
              :rows="3"
              v-model="form.introduction_content"
            ></el-input>
          </el-form-item>
          <el-form-item label="课程详情">
            <el-input
              type="textarea"
              :rows="3"
              v-model="form.introduction_text"
            ></el-input>
          </el-form-item>
          <el-form-item label="备注">
            <el-input
              type="textarea"
              :rows="3"
              v-model="form.remark"
            ></el-input>
          </el-form-item>
        </div>
      </div>
      <div class="part">
        <p class="part-title">教练信息</p>
        <el-form-item label="教练" v-permission="'updateNeedCoach'">
          <coachSelect
            :coachid.sync="coach_id"
            @change="changeCoach"
          ></coachSelect>
        </el-form-item>
        <div class="row" v-if="coachInfo.id">
          <el-tooltip class="item" effect="dark" content="点击头像可查看详情">
            <div class="flex coach-card" style="margin-left: 60px">
              <img
                :src="coachInfo.head_portrait"
                class="avatar"
                @click="toCoachDetail"
              />
              <div class="user-info flex-col jc-e">
                <div style="font-size: 16px">
                  {{ coachInfo.real_name }}&nbsp;&nbsp;{{
                    coachInfo.sex == 1 ? "男" : "女"
                  }}
                </div>
                <div>教龄：{{ coachInfo.teaching_age }}年</div>
                <div>类目：{{ coachInfo.teaching_categories }}</div>
              </div>
            </div>
          </el-tooltip>
        </div>
      </div>
      <!-- <div class="part" v-if="form.type == 'course'">
        <p class="part-title">上课用户</p>
        <div style="padding-left: 80px">
          <div style="padding: 10px 0" class="flex al-c">
            <p class="label">搜索</p>
            <el-input v-model="query" placeholder="请输入用户电话"></el-input>
            <el-button @click="getUser" type="primary">搜索</el-button>
          </div>

          <div>
            <el-transfer
              v-model="checkUser"
              :props="transprops"
              :data="userList"
              :titles="['用户列表', '选中用户']"
            >
            </el-transfer>
          </div>
        </div>
      </div> -->
    </el-form>
    <div style="height: 80px"></div>
    <div class="btns flex" v-if="type == 'process'">
      <el-button @click="back" style="margin-rigth: 20px">返 回</el-button>
      <el-button
        type="danger"
        class="butt"
        @click="opanrefuse = true"
        size="medium"
        >拒绝</el-button
      >
      <!-- generateCourse -->
      <el-button
        type="success"
        class="butt"
        @click="agree"
        size="medium"
        style="margin-left: 20px"
        >通过审核</el-button
      >
    </div>
    <div class="btns flex" v-else>
      <el-button @click="back" style="margin-rigth: 20px">返 回</el-button>
      <el-button
        type="primary"
        class="butt"
        @click="addCourse"
        size="medium"
        v-if="id == ''"
        >发布需求</el-button
      >
      <el-button
        type="primary"
        class="butt"
        @click="updateCourse"
        size="medium"
        v-else
        >修改需求</el-button
      >
    </div>
    <el-dialog title="拒绝审核" :visible.sync="opanrefuse" width="400px">
      <el-form label-width="80px">
        <el-form-item label="拒绝理由">
          <el-input type="textarea" v-model="remark"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="refuse">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="选择场地" :visible.sync="venueShow" width="50%">
      <div class="">
        <el-form :inline="true">
          <el-form-item label="场地名称">
            <el-input v-model="v_name"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getVenueList(1)">搜索</el-button>
          </el-form-item>
        </el-form>
        <auto-table
          :DataList="DataList"
          :option="Option"
          :total="Total"
          @changePage="getVenueList"
        >
          <template #handler="{ row }">
            <div>
              <el-button type="primary" @click="changeVenue(row)"
                >选择</el-button
              >
            </div>
          </template>
        </auto-table>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { ObjectChangedValue } from "@/js/utils.js";
import mechanismSelect from "../../components/select/mechanismSelect.vue";
import coachSelect from "../../components/select/coachSelect.vue";
export default {
  components: {
    mechanismSelect,
    coachSelect,
  },
  data() {
    return {
      nav: { firstNav: "需求管理中心", secondNav: "发布课程需求" },
      type: "",
      readOnly: false,
      form: { order_type: 0, longitude: "", latitude: "",coupon_use:1},
      cityoptions: [],
      categories_child_list: [],
      coach_id: "",
      age_grade_list: [
        { name: "学龄前", value: 2 },
        { name: "学龄", value: 3 },
        { name: "成人", value: 1 },
        { name: "亲子", value: 4 },
        { name: "不限人群", value: 0 },
      ],
      downTime: 7,
      downList: [
        {
          name: 7,
          label: "一周",
        },
        {
          name: 15,
          label: "十五天",
        },
        {
          name: 29,
          label: "一个月",
        },
      ],
      region: "",
      ms: "false",
      cityProps: {
        value: "district",
        label: "district",
        children: "childrens",
      },
      send_sms: 0,
      cityList: [],
      imageUrl3: "",
      background_image: "",
      isClear: true,
      if_clear: true,
      city: "",
      base_list: [
        { name: "零基础", value: 1 },
        { name: "初入门", value: 2 },
        { name: "中级水平", value: 3 },
        { name: "高级水平", value: 4 },
      ],
      id: "",
      settlement_type: 1,
      appointment_type: 2,
      pay_way: "1",
      temp: {},
      user_id: "",
      userList: [],
      checkUser: [],
      query: "",
      transprops: {
        key: "user_id",
        label: "nick_name",
      },
      coachInfo: {},
      rules: {
        title: [{ required: true, message: "请输入课程名称", trigger: "blur" }],
        course_num: [
          { required: true, message: "请输入课程数量", trigger: "blur" },
        ],
        categories: [
          {
            required: true,
            message: "请选择课程类目",
            trigger: "change",
          },
        ],
        service_type: [
          {
            required: true,
            message: "请选择教学方式",
            trigger: "change",
          },
        ],
        length_of_lesson: [
          {
            required: true,
            message: "请输入单课时长",
            trigger: "blur",
          },
        ],
        amount: [
          {
            required: true,
            message: "请输入课程总价",
            trigger: "blur",
          },
        ],
        pay_point: [
          { required: true, message: "请输入权益点", trigger: "blur" },
        ],
        time_slot: [
          { required: true, message: "请输入上课时间段", trigger: "blur" },
        ],
      },
      remark: "",
      opanrefuse: false,

      venueShow: false,
      DataList: [],
      Option: [
        { name: "名称", value: "name" },
        { name: "金额(元)", value: "share_price" },
        { name: "地址", value: "address" },
        { name: "操作", value: "handler", type: "custom" },
      ],
      Total: 0,
      type: "",
      v_name: "",
      currentVenue: {},
      adminInfo: JSON.parse(localStorage.getItem("managerInfo")),
    };
  },
  created() {
    this.cityoptions = JSON.parse(localStorage.getItem("addrList"));
    this.type = this.$route.query.type;
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.getCourseInfo();
      this.getUserIdsInfo();
    } else {
      this.$set(this.form, "is_receiving", 1);
      this.$set(this.form, "user_bond", 3);
      this.$set(this.form, "coach_bond", 3);
      this.$set(this.form, "settlement_course", 0);
      this.$set(this.form, "create_type", 1);
      this.$set(this.form, "type", "need");
      this.coachInfo = {};
    }
  },
  mounted() {
    this.getCategoryList();
  },
  computed: {
    settlement_Price() {
      let num = this.form.course_num - (this.form.settlement_course || 0);
      if (this.settlement_type == 1) {
        return (
          num *
          (this.form.coach_settlement_cash +
            this.form.mechanism_settlement_cash)
        ).toFixed(2);
      } else {
        return (num * this.form.settlement_cash).toFixed(2);
      }
    },

    profitCheck() {
      if (this.settlement_type == 2) {
        if (this.settlement_cash == 0) {
          this.$message({ message: "单节结算价不能为0" });
          return false;
        }
        if (
          Number(this.form.mechanism_profit) + Number(this.form.coach_profit) >
          1
        ) {
          this.$message({ message: "结算比例不能大于1" });
          return false;
        }
      }
      return true;
    },
    priceCheck() {
      let num = this.form.course_num - (this.form.settlement_course || 0);
      if (this.settlement_type == 1) {
        if (
          num *
            (Number(this.form.coach_settlement_cash) +
              Number(this.form.mechanism_settlement_cash)) >
          this.form.amount
        ) {
          return false;
        }
      } else {
        if (num * Number(this.form.settlement_cash) > this.form.amount) {
          return false;
        }
      }
      return true;
    },
  },
  methods: {
    changeOrderType(v){
        if(v == 4){
           this.form.coach_settlement_cash = 0
            this.form.mechanism_settlement_cash = 0
        }
    },
    changeVenue(v) {
      this.currentVenue = v;
      this.form.venue_id = v.id;
      this.form.province = v.province;
      this.form.city = v.city;
      this.form.district = v.district;
      this.form.latitude = v.latitude;
      this.form.longitude = v.longitude;
      this.form.address = v.address;
      this.venueShow = false;
    },
    changeService(v) {
      if (v != 3) {
        this.currentVenue = {};
        this.form.venue_id = 0;
      }
      this.getCategoryList()
    },
    openVenue() {
      this.getVenueList(1);
      this.venueShow = true;
    },
    getVenueList(v) {
      let page = v || 1;
      this.$axios({
        url: "/user/mechanism/venue/queryManagerListPage",
        params: {
          currentPage: page,
          pageSize: 10,
          coach_id: this.form.coach_id || null,
          name: this.v_name || null,
        },
      }).then((res) => {
        this.DataList = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
    changeCreateType(v) {
      if (v === 1 && this.form.type == "order" && this.id == "") {
        this.form.type = "need";
      }
    },
    opanre() {
      this.opanrefuse = true;
    },
    // 教练信息
    getCoachInfo() {
      let url = "/user/coach/findById";
      this.$axios
        .get(url, {
          params: {
            id: this.form.coach_id,
          },
        })
        .then((res) => {
          this.coachInfo = res.data.data;
        });
    },
    toCoachDetail() {
      this.$router.push({
        name: "coachDetails",
        query: { id: this.coachInfo.id },
      });
    },
    changeCoach(v) {
      this.coachInfo = v;
    },
    endtimeChange() {
      let s = new Date(this.form.start_time);
      let e = new Date(this.form.end_time);
      if (s >= e) {
        this.$message({ message: "结束时间不能小于开始时间" });
        this.form.end_time = "";
      }
    },
    lableChange(v) {
      this.appointment_type = v;
      this.pay_way = v;
      this.form.appointment_type = v;
      this.form.pay_way = v;
    },
    //拒绝审核
    refuse() {
      let data = {
        id: this.form.id,
        status: 2,
        remark: this.remark,
      };
      let url = "/user/userCourseNeed/update";
      this.$axios({
        url,
        data,
        method: "post",
      }).then((res) => {
        if (res.data.code == 0) {
          this.$router.back();
          this.$message({ message: "已拒绝" });
        } else {
          this.$message({ message: res.data.message });
        }
      });
    },
    // 通过审核
    agree() {
      if (!this.priceCheck) {
        this.$message({ message: "结算总价不能大于课程价格" });
        return;
      }
      if (!this.profitCheck) {
        return;
      }
    
      let data = ObjectChangedValue(this.temp, this.form);
      let url = "/user/userCourseNeed/update";
      data.id = this.form.id;
      data.status = 3;
      // data.user_ids = this.checkUser;

      this.$axios({
        url,
        data,
        method: "post",
      }).then((res) => {
        if (res.data.code == 0) {
          this.$router.back();
          this.$message({ message: "成功" });
        } else {
          this.$message({ message: res.data.message });
        }
      });
    },
    //需求用户信息
    getUserIdsInfo() {
      let url = "/user/userNeedConnected/query";
      this.$axios
        .get(url, {
          params: {
            need_id: this.id,
          },
        })
        .then((res) => {
          this.userList = res.data.data;
          this.checkUser = res.data.data.map((item) => item.user_id);
        });
    },
    //选择机构
    mecChange(item) {
      this.form.address = item.mechanism_addr;
      this.form.longitude = item.longitude;
      this.form.latitude = item.latitude;
      this.form.province = item.province;
      this.form.city = item.city;
      this.form.district = item.district;
      this.cityList = [item.province, item.city, item.district];
    },
    //搜索用户
    getUser() {
      if (this.query) {
        let url = "/user/user/queryByMessage";
        this.$axios
          .get(url, {
            params: {
              login_name: this.query,
            },
          })
          .then((res) => {
            this.userList = res.data.data.rows;
          });
      }
    },
    // 课程需求信息
    getCourseInfo() {
      let url = "/user/userCourseNeed/findById";
      let params = {
        id: this.id,
      };
      this.$axios
        .get(url, {
          params,
        })
        .then((res) => {
          this.form = res.data.data;
          this.temp = { ...res.data.data };
          this.appointment_type = this.form.appointment_type;
          this.pay_way = this.form.pay_way + "";
          this.settlement_type = this.form.settlement_type;
          this.cityList = [
            this.form.province,
            this.form.city,
            this.form.district,
          ];
          if (this.form.type == "need") {
            let range = this.form.amount_range.split("-");
            this.$set(this.form, "min", range[0]);
            this.$set(this.form, "max", range[1]);
          }

          this.form.coach_id && this.getCoachInfo();
        });
    },
    //城市选择
    cityChange(v) {
      this.form.province = v[0];
      this.form.city = v[1];
      this.form.district = v[2];
    },
    changeAddr(v) {
      this.$set(this.form, "latitude", v.addrPoint.lat);
      this.$set(this.form, "longitude", v.addrPoint.lng);
      if (v.address) {
        this.$set(this.form, "address", v.address);
        this.form.province = v.province;
        this.form.city = v.city;
        this.form.district = v.district;
        this.cityList = [v.province, v.city, v.district];
      }
    },
    // 添加需求
    addCourse() {
      if (!this.priceCheck) {
        this.$message({ message: "结算总价不能大于课程价格" });
        return;
      }
      if (!this.profitCheck) {
        return;
      }
      if (this.form.type == 'course' && this.form.user_bond < 1 && this.form.course_num >= 10) {
        this.$message({ message: "保证课节数不能为0" });
        return;
      }
      if (!this.form.address) {
        this.$message("请填写地址");
        return;
      }
      if (!this.form.longitude) {
        this.$message("请填写经纬度");
        return;
      }
      if (this.form.type == "need" && !this.form.min && !this.form.max) {
        this.$message({ message: "请输入课程预算" });
        return;
      }

       
      if (this.form.type == "need") {
        this.form.amount_range = this.form.min + "-" + this.form.max;
        if(this.form.create_type != 1 && (!this.form.name || !this.form.phone)){
                this.$message({ message: "请填写联系人和联系电话" });
                return;
        }
      }
     if (this.form.order_type == 4 && this.form.min && this.form.max) {
        this.form.amount_range = this.form.min + "-" + this.form.max;
      }

      this.$refs.form.validate((valid) => {
        if (valid) {
          let url = "/user/userCourseNeed/insert";
          let data = {
            ...this.form,
            settlement_type: this.settlement_type,
            appointment_type: this.appointment_type,
          };
          // data.user_ids = this.checkUser;
          data.settlement_course = this.form.settlement_course || 0;
          data.usable_coupon_num = this.form.usable_coupon_num || 0;
          data.pay_way = this.pay_way;
          data.status = 1;
          // data.create_type = 1;
          data.coach_id = this.coach_id;
          data.admin_id = this.adminInfo.id;
          data.admin_user_id = this.adminInfo.user_id;
          let msec = new Date().getTime() + 1000 * 60 * 60 * 24 * this.downTime;
          data.auto_down_time = new Date(msec).Format("yyyy-MM-dd hh:mm:ss");
          data.send_sms = this.send_sms;
          const loading = this.$loading({
            background: "rgba(0, 0, 0, 0.7)",
          });
          this.$axios({
            url,
            data,
            method: "post",
          })
            .then((res) => {
              loading.close();
              if (res.data.code == 0) {
                this.$notify({
                  type: "success",
                  message: "添加成功",
                  duration: 3000,
                });
                this.$router.back();
              } else {
                this.$message({ message: res.data.message });
              }
            })
            .catch((err) => {
              loading.close();
            });
        } else {
          this.$message({ message: "请填写必填信息" });
        }
      });
    },
    // 更新需求信息
    updateCourse() {
      if (!this.priceCheck) {
        this.$message({ message: "结算总价+场地费不能大于课程价格" });
        return;
      }
      if (!this.profitCheck) {
        return;
      }
      if (this.form.type == 'course' && this.form.user_bond < 1 && this.form.course_num >= 10) {
        this.$message({ message: "保证课节数不能为0" });
        return;
      }
       if (this.form.type == "need") {
        this.form.amount_range = this.form.min + "-" + this.form.max;
          if(this.form.create_type != 1 && (!this.form.name || !this.form.phone)){
                this.$message({ message: "请填写联系人和联系电话" });
                return;
        }
      }

       if (this.form.order_type == 4 && this.form.min && this.form.max) {
        this.form.amount_range = this.form.min + "-" + this.form.max;
      }
      let data = ObjectChangedValue(this.temp, this.form);
      if (this.temp.coach_id != this.coach_id) {
        data.coach_id = this.coach_id;
      }
      data.settlement_type = this.settlement_type;
      data.appointment_type = this.appointment_type;
      data.pay_way = this.pay_way;

      let url = "/user/userCourseNeed/update";
      data.id = this.form.id;
      // data.user_ids = this.checkUser;
      const loading = this.$loading({
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.$axios({
        url,
        data,
        method: "post",
      })
        .then((res) => {
          loading.close();
          this.$router.back();
          this.$message({ message: "成功" });
        })
        .catch((err) => {
          loading.close();
        });
    },
    //课程类目列表
    getCategoryList() {
      let url = "/user/mechanismCategory/queryListPageChild";
      let data = {
        status: 2,
        type: 2,
        source: "课程",
      };
      if(this.form.service_type == 4){
          data.source = "陪练"
      }
      this.$axios
        .get(url, {
          params: data,
        })
        .then((res) => {
          this.categories_child_list = res.data.data;
        });

       
    },
    back() {
      this.$router.back();
    },
    uploadFace(file) {
      this.form.face_url = file;
    },
    uploadIntroduction(file) {
      this.form.introduction_url = file;
    },
  },
};
</script>
  
<style scoped lang="less">
.btns {
  position: fixed;
  padding: 10px 0 20px 30px;
  width: 80%;
  bottom: 0;
  background-color: #fff;
  justify-content: flex-end;
}

.title {
  padding: 4px 30px 0px 5px;
  font-size: 20px;
  margin-bottom: 0;
}

.row {
  width: 100%;
}

.part {
  background-color: #fff;
}

.part-title {
  box-sizing: border-box;
  padding-left: 20px;
  line-height: 50px;
  font-size: 16px;
  border-bottom: 1px solid rgb(226, 220, 220);
  margin-bottom: 20px;
  font-weight: 600;
}

.w80 {
  width: 500px !important;
}

.price {
  padding: 10px 0 10px 50px;
  font-size: 14px;

  div {
    margin-left: 20px;
  }
}

.label {
  font-size: 16px;
  color: #606266;
  margin-right: 10px;
}

.coach-card {
  width: fit-content;
  overflow: hidden;
  margin-left: 20px;
  margin-bottom: 10px;

  .avatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }

  .user-info {
    margin-left: 10px;
    font-size: 14px;
  }
}
</style>